interface CurrencyFormatOptions {
  style?: string;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const defaultCurrencyOptions: CurrencyFormatOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
}

/**
 *
 * @param currencyNumber - The amount to be formatted
 * @param options - Optional object that can be use to customize formatting
 * @param locale - optional international currency format. Default: "USD"
 *
 * @example
 * currencyFormatter(100): // Output: "$100.00"
 * currencyFormatter(100.98) // Output: "$100.98"
 * currencyFormatter(1999.97 // Output: "$1,999.97"
 * currencyFormatter(15, {currency: 'EUR'}) // Output:"€15.00
 * currencyFormatter(15, {currency: 'JPY', minimumFractionDigit, maximumFractionDigits: 0 } //Output: ¥15
 */
export const currencyFormatter = (currencyNumber: string | number , options?: CurrencyFormatOptions, locale: string = 'en-US' ) : string | null=> {
  
  const _currency = typeof currencyNumber === 'string'
    ? parseFloat(currencyNumber.replace(/[^\d\-+.]/g, ''))
    : currencyNumber;

  if (!isNaN(_currency)) {
    const defaultOptions: CurrencyFormatOptions = {
      ...defaultCurrencyOptions
    };

    const mergedOptions: any = { ...defaultOptions, ...options };
    return _currency?.toLocaleString(locale, { ...mergedOptions });
  }

  return null;
}

export const convertCurrencyToNumber = (value: any ): number => {
  if (!value) {
    return 0;
  }

  if (typeof value === 'number') {
    return value;
  }
  
  return parseFloat(value.replace("$", "").replace(/,/g, ""));
}

export const formatCurrency = (value: string): string => {
  return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '';
}