import { Component, EventEmitter, Input, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { InlineSVGModule } from "ng-inline-svg-2";
import { FormsModule } from "@angular/forms";
import { InputDirectiveModule } from "@app/shared/directives/input-directive/input-directive.module";
import { NgIf } from '@angular/common'

@Component({
  standalone: true,
  selector: 'app-input-search-one',
  imports:[FormsModule, InlineSVGModule, InputDirectiveModule,  NgIf],
  template:`
    <div class="search-box" [class]="size">
      <label class="input-search" [class.dark]="dark">
        <span class="icon" [inlineSVG]="icon.searchBlack.src"></span>
        <input type="text" placeholder="Search"
               appDebounceInput
               [value]="searchKeyword"
               (onEvent)="search($event.target.value)">
        <span *ngIf="delete && searchKeyword"
              class="icon-suffix close" [inlineSVG]="icon.closeIh.src"
              (click)="deleteSearch()"
        ></span>
      </label>
    </div>
  `,
  styles:[`
    .search-box {
      padding-top: 0;
    }

    .input-search {
      position: relative;
      height: inherit;
      width: 100%;
      padding: 0.75rem 1rem;
      border-radius: 8px;
      border: 1px solid #D6D3D7;
      display: flex;
    }

    .input-search.dark {
      background-color: #F6F6F6;
    }

    .icon {
      height: 20px;
      padding-right: 0.5rem;
    }

    input {
      width: 100%;
      padding-right: 0.75rem;
      border: none;
      background-color: initial;
      font-size: 0.875rem;
    }

    .icon-suffix {
      position: absolute;
      right: 1rem;
      width: 8px;
      fill: #949494;
      cursor: pointer
    }

    .search-box.sm .input-search {
      padding: 0.5rem;
    }

    .search-box.sm .icon {
      padding-right: 0.25rem;
    }

  `]
})
export class InputSearchOneComponent {

  @Input()
  public delete: boolean = false;

  @Input()
  public dark: boolean = false;

  @Input()
  public size: 'sm' | '' = '';

  @Output()
  public onSearch: EventEmitter<string> = new EventEmitter<string>();

  public icon = iconLib;
  public searchKeyword: string = '';

  public search(keyword: any): void {
    this.searchKeyword = keyword;
    this.onSearch.emit(this.searchKeyword)
  }

  public deleteSearch(): void {
    this.searchKeyword = ''
    this.onSearch.emit('');
  }

}
