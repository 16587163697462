export enum Status {
  ACTIVE = "ACTIVE",
  APPROVED = "APPROVED",
  ASSIGNED = "ASSIGNED",
  COMPLETED = "COMPLETED",
  COMPLETE = "COMPLETE",
  DRAFT = "DRAFT",
  DONE = "DONE",
  ESCALATE = "ESCALATE",
  IGNORE = "IGNORE",
  IN_PROGRESS = "IN_PROGRESS",
  INSPECT = "INSPECT",
  NEW = "NEW",
  NOT_STARTED = "NOT_STARTED",
  ON_GOING = "ON_GOING",
  ON_HOLD = "ON_HOLD",
  OPEN = "OPEN",
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
  REVIEW = "REVIEW",
  SCHEDULED = "SCHEDULED",
  TODO = "TODO",
  WAITING = "WAITING",
  VIOLATION = "VIOLATION",
  DENIED = "DENIED",
}

export const StatusName: Record<string, string> = {
  [Status.ACTIVE]: "Active",
  [Status.APPROVED]: "Approved",
  [Status.ASSIGNED]: "Assigned",
  [Status.COMPLETED]: "Completed",
  [Status.COMPLETE]: "Complete",
  [Status.DONE]: "Done",
  [Status.DRAFT]: "Draft",
  [Status.IN_PROGRESS]: "In Progress",
  [Status.NEW]: "New",
  [Status.NOT_STARTED]: "Not Started",
  [Status.ON_GOING]: "Ongoing",
  [Status.ON_HOLD]: "On Hold",
  [Status.OPEN]: "Open",
  [Status.PENDING]: "Pending",
  [Status.RESOLVED]: "Resolved",
  [Status.SCHEDULED]: "Scheduled",
  [Status.TODO]: "To Do",
  [Status.WAITING]: "Waiting",
  [Status.VIOLATION]: "Violation",
  [Status.DENIED]: "Denied",
};
