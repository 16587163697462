// absolute.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value === 1 || value === true || value === '1' || value === 'true' || value === 'Y') {
      return 'Yes';
    } else if (value === 0 || value === false || value === '0' || value === 'false' || value === 'N') {
      return 'No';
    } else {
      return '-';
    }
  }
}
