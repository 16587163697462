<button mat-button [class]="'btn position-relative ' + class"
        [class.w-100]="full"
        [disabled]="disabled"
        (click)="click()">

  <span [class.show]="isLoading" [class.hide]="!isLoading">
    <app-loader *ngIf="isLoading" [dark]="dark"></app-loader>
  </span>

  <span class="align-center"
        [class.show]="!isLoading" [class.hide]="isLoading"
  >
    @if(icon.src) {
      <span class="mr-7 d-flex"
            [class]="iconClass">
        <img [src]="icon.src" [alt]="icon.alt" [style.height]="iconHeight">
      </span>
    } 

    @if(_ico) {
      <roam-icon class="mr-7" [name]="_ico" [color]="_clr ? _clr : 'white'" />
    }

    <span *ngIf="iconSVG"
          [class]="'d-flex mr-7 ' + iconClass " [inlineSVG]="iconSVG"></span>

    <span>{{ label }}</span>
  </span>
</button>
