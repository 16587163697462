import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "bytesize",
})
export class ByteSizePipe implements PipeTransform {
  transform(value: number): string {
    if (value === 0) {
      return "0 B";
    } else if (!value) {
      return "";
    }

    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(value) / Math.log(1024));
    const formattedValue = (value / Math.pow(1024, i)).toFixed(2);

    return `${formattedValue} ${sizes[i]}`;
  }
}
