import { Directive, HostListener } from "@angular/core";
import { AbstractInputDirective } from "@app/shared/directives/input-directive/abstract-input.directive";

@Directive({
  standalone: true,
  selector: '[appDebounceInput]'
})
export class DebounceInputDirective extends AbstractInputDirective{

  constructor() {
    super()
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: any): void {
    event.preventDefault();
    this.emitEvent$.next(event)
  }

}
