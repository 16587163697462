import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageOfRequest',
  standalone: true
})
export class AgeOfRequestPipe implements PipeTransform {

  transform(date: string | Date): string {
    const date1 = new Date(date);
    const date2 = new Date();
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} days`;
  }
}
