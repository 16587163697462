import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { debounceTime, distinctUntilChanged, Subject, takeUntil, tap } from "rxjs";

@Directive({
  selector: '[appAbstractInput]'
})
export class AbstractInputDirective implements OnInit, OnDestroy{

  @Input()
  public debounceTime: number = 500;

  @Output()
  public onEvent: EventEmitter<any> = new EventEmitter<any>();

  protected emitEvent$: Subject<any> = new Subject<any>();
  protected subscription$: Subject<void> = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    this.emitEvent();
  }

  public ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }

  private emitEvent(): void {
    this.emitEvent$.pipe(
      takeUntil(this.subscription$),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      tap(value => this.emitChange(value))
    ).subscribe()
  }

  public emitChange(value: any): void {
    this.onEvent.emit(value)
  }
}

