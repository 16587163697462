import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyKey',
  standalone: true
})
export class PropertyKeyPipe implements PipeTransform {

  transform<T>(items: T[], property: keyof T): any {
    if (!items || items.length === 0 || !property) {
      return null;
    }
    return items[0][property];
  }

}
