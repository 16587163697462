import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { DecimalPipe } from "@angular/common";

@Directive({
  selector: '[appCurrencyInput]'
})
export class CurrencyInputDirective {

  @Input()
  public isNumberOnly!: boolean;

  @Input()
  public isCurrency!: boolean;

  @Input()
  public maxCharacter!: number;

  // Allow decimal numbers. The \. is only allowed once to occur
  regex = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys  = ['Backspace', 'Tab', 'End', 'Home'];

  @Input() maxlength!: number;
  @Input() min!: number;
  @Input() max!: number;

  @Input() allowDecimals : boolean | undefined | null;
  @Input() allowSign     = false;
  @Input() decimalSeparator   = '.';

  // --------------------------------------
  //  Regular expressions
  integerUnsigned = '^[0-9]*$';
  integerSigned   = '^-?[0-9]+$';
  decimalUnsigned = '^[0-9]+(.[0-9]+)?$';
  decimalSigned   = '^-?[0-9]+(.[0-9]+)?$';

  /**
   * Class constructor
   * @param hostElement
   */
  constructor(private hostElement: ElementRef) { }

  /**
   * Event handler for host's change event
   */
  @HostListener('change', ['$event']) onChange(): void {
    if (this.isNumberOnly) {
      this.validateValue(this.hostElement.nativeElement?.value);
    }
  }

  /**
   * Event handler for host's keydown event
   * @param event
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  @HostListener('keypress', ['$event']) onKeyPress(event: Event): void {
    const evtKey = <KeyboardEvent>event;

    if(this.isNumberOnly) {
      let listOfAllowedKey = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home', '.'];
      const allowOnlyOneDecimal = this.hostElement.nativeElement?.value.split('.').length > 1;

      if (!this.allowDecimals || allowOnlyOneDecimal) {
        listOfAllowedKey = listOfAllowedKey.filter(key => key !== '.')
      }

      if (listOfAllowedKey.indexOf(evtKey.key) !== -1 ||
        // Allow: Ctrl+A
        (evtKey.key === 'a' && (evtKey.ctrlKey || evtKey.metaKey)) ||
        // Allow: Ctrl+C
        (evtKey.key === 'c' && (evtKey.ctrlKey || evtKey.metaKey)) ||
        // Allow: Ctrl+V
        (evtKey.key === 'v' && (evtKey.ctrlKey || evtKey.metaKey)) ||
        // Allow: Ctrl+X
        (evtKey.key === 'x' && (evtKey.ctrlKey || evtKey.metaKey))) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((evtKey.shiftKey || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(evtKey.key) === -1)) {
        evtKey.preventDefault();
      }

      // restrict to 2 decimal places
      this.limitedDecimal(this.hostElement.nativeElement?.value, event);

    }

    if(this.maxCharacter && (this.maxCharacter < (this.hostElement.nativeElement?.value.length + 1))) {
      evtKey.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(): void {
    if(this.isCurrency) {
      const {value} = this.hostElement.nativeElement;
      const newValue = value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      const originalLength = value.length;
      let caretPos = this.hostElement.nativeElement.selectionStart;

      this.hostElement.nativeElement.value = newValue.replace(/^0+/, '');

      const updatePos = newValue.length;
      caretPos = updatePos - originalLength + caretPos;

      this.hostElement.nativeElement.setSelectionRange(caretPos, caretPos);
    }
  }

  limitedDecimal(value: string, event: Event): void {
    const restrictedDecimal     = (value.indexOf('.') > -1 && (value.split('.')[1].length > 2));

    if (this.allowDecimals && restrictedDecimal) {
      event.preventDefault();
    }
  }

  /**
   * Test whether value is a valid number or not
   * @param value
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  validateValue(value: string): void {

    let newValue = value;
    let regex = '';
    if (!this.allowDecimals && !this.allowSign) {regex = this.integerUnsigned}
    if (!this.allowDecimals && this.allowSign) {regex = this.integerSigned}
    if (this.allowDecimals && !this.allowSign) {regex = this.decimalUnsigned}
    if (this.allowDecimals && this.allowSign) {regex = this.decimalSigned}

    const firstCharacter = value.charAt(0);
    if (firstCharacter === this.decimalSeparator) {
      newValue = 0 + newValue;
    }

    const lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter === this.decimalSeparator) {
      newValue = newValue + 0;
    }

    const valid: boolean = (new RegExp(regex)).test(newValue);
    if(this.allowDecimals) {
      newValue = newValue.includes('.') ? newValue : newValue.replace(/^0+/, '');
      this.hostElement.nativeElement['value'] = valid ? newValue : 0;
    } else {
      this.hostElement.nativeElement['value'] = newValue;
    }
  }
  //
  // currencyChars = new RegExp('[\.,]', 'g'); // remove commas and dots
  //
  // constructor(public el: ElementRef, public renderer: Renderer2, private decimalPipe: DecimalPipe) {}
  //
  // ngOnInit() {
  //   this.format(this.el.nativeElement.value);
  // }
  //
  // @HostListener('input', ["$event.target.value"]) onInput(e: string) {
  //   this.format(e);
  // };
  //
  // @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
  //   event.preventDefault();
  //   const pastedValue = event.clipboardData?.getData('text/plain') || '';
  //   this.format(pastedValue);
  // }
  //
  // @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
  //   // Allow backspace
  //   // if (event.key === 'Backspace') {
  //   //   return;
  //   // }
  //   //
  //   // // Allow only digits and a single dot
  //   // if (!/[\d.]/.test(event.key)) {
  //   //   event.preventDefault();
  //   // }
  //   //
  //   // // Allow only one dot
  //   // if (event.key === '.' && this.el.nativeElement.value.includes('.')) {
  //   //   event.preventDefault();
  //   // }
  // }
  //
  // format(val: string) {
  //
  //
  //   const newValue = val.replace('.00', '').replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  //
  //   const originalLength = newValue.length;
  //   let caretPos = this.el.nativeElement.selectionStart;
  //
  //   this.el.nativeElement.value = newValue.replace(/^0+/, '')+'.00';
  //
  //   const updatePos = newValue.length;
  //   caretPos = updatePos - originalLength + caretPos;
  //
  //   this.el.nativeElement.setSelectionRange(caretPos, caretPos);
  // }


}
