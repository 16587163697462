import { Pipe, PipeTransform } from '@angular/core';
import { iconSource } from "@app/core/utils/color.utils";

@Pipe({
  standalone: true,
  name: 'icon'
})
export class IconPipe implements PipeTransform {

  transform(status: string | number): string {
    return iconSource.get(status.toString()) || ''
  }
}
