import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { formatCurrency } from "@app/core/helper/currency.helper";

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  @Input()
  public isNumberOnly: boolean = true;

  @Input()
  public isCurrency!: boolean;

  @Input()
  public maxCharacter!: number;

  @Input()
  public isRef!: boolean;

  // Allow decimal numbers. The \. is only allowed once to occur
  regex = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
  regexRef =  /^[a-zA-Z0-9\s_\-]+$/;

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys  = ['Backspace', 'Tab', 'End', 'Home'];

  @Input() maxlength!: number;
  @Input() min!: number;
  @Input() max!: number;

  @Input() allowDecimals : boolean | undefined | null = true;
  @Input() allowSign     = false;
  @Input() decimalSeparator   = '.';
  hasFormattedOnce: boolean = false

  // --------------------------------------
  //  Regular expressions
  integerUnsigned = '^[0-9]*$';
  integerSigned   = '^-?[0-9]+$';
  decimalUnsigned = '^[0-9]+(\.[0-9]+)?$';
  decimalSigned   = '^-?[0-9]+(\.[0-9]+)?$';

  /**
   * Class constructor
   * @param hostElement
   */
  constructor(private hostElement: ElementRef) {}

  /**
   * Event handler for host's change event
   */
  @HostListener('change', ['$event']) onChange(): void {
    if (this.isCurrency) {
      this.validateValue(this.hostElement.nativeElement.value);
    }
  }

  /**
   * Event handler for host's keydown event
   * @param event
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  @HostListener('keypress', ['$event']) onKeyPress(event: Event): void {
    const evtKey = <KeyboardEvent>event;

    if(this.isRef) {
      this.regex = new RegExp(this.regexRef)
    }
    
    if(this.isNumberOnly) {
      let listOfAllowedKey = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home', '.'];
      const allowOnlyOneDecimal = this.hostElement.nativeElement?.value.split('.').length > 1;

      if (!this.allowDecimals || allowOnlyOneDecimal) {
        listOfAllowedKey = listOfAllowedKey.filter(key => key !== '.')
      }

      if (listOfAllowedKey.indexOf(evtKey.key) !== -1 ||
        // Allow: Ctrl+A
        (evtKey.key === 'a' && (evtKey.ctrlKey || evtKey.metaKey)) ||
        // Allow: Ctrl+C
        (evtKey.key === 'c' && (evtKey.ctrlKey || evtKey.metaKey)) ||
        // Allow: Ctrl+V
        (evtKey.key === 'v' && (evtKey.ctrlKey || evtKey.metaKey)) ||
        // Allow: Ctrl+X
        (evtKey.key === 'x' && (evtKey.ctrlKey || evtKey.metaKey))) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((evtKey.shiftKey || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(evtKey.key) === -1)) {
        evtKey.preventDefault();
      }

      // restrict to 2 decimal places
      this.limitedDecimal(this.hostElement.nativeElement?.value, event);

    }

    if(this.maxCharacter && (this.maxCharacter < (this.hostElement.nativeElement?.value.length + 1))) {
      evtKey.preventDefault();
    }

    if (!this.regex.test(evtKey.key) && this.isRef) {
      evtKey.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(): void {
    if (this.isCurrency) {
      let { value } = this.hostElement.nativeElement;

      if (!value) {
        this.hostElement.nativeElement.value = null;
      } else {

        const elementRef = this.hostElement as ElementRef<HTMLInputElement>;
        let cursorPosition = elementRef.nativeElement.selectionStart || 0;
      
        if(value.includes('.')) {
          const dotIndex = value.indexOf(".");
          const integerPart = value.substring(0, dotIndex);
          const decimalPart = value.substring(dotIndex);
          const formattedInteger = formatCurrency(integerPart);
          const formattedDecimal = formatCurrency(decimalPart).substring(0, 2);
          this.hostElement.nativeElement.value = `$${formattedInteger}.${formattedDecimal}`;
        } else {
          this.hostElement.nativeElement.value = `$${formatCurrency(value)}`
        }
        
        cursorPosition = this.hostElement.nativeElement.value.length  - value.length + cursorPosition ;
        this.hostElement.nativeElement.setSelectionRange(cursorPosition , cursorPosition);
      
      }
    }
  }

  limitedDecimal(value: string, event: Event): void {
    const restrictedDecimal     = (value.indexOf('.') > -1 && (value.split('.')[1].length > 2));

    if (this.allowDecimals && restrictedDecimal) {
      event.preventDefault();
    }
  }

  /**
   * Test whether value is a valid number or not
   * @param value
   */
  validateValue(value: string): void {

    let newValue = value;
    let regex = this.decimalSigned;
 
    const firstCharacter = value.charAt(0);
    if (firstCharacter === this.decimalSeparator) {
      newValue = 0 + newValue;
    }

    const lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter === this.decimalSeparator) {
      newValue = newValue + 0;
    }

    const valid: boolean = (new RegExp(regex)).test(newValue);
    this.hostElement.nativeElement['value'] = newValue;
  }
}
