import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: '[appCreditMmYy]'
})
export class CreditMmYyDirective {
  @HostListener('input', ['$event']) onInput(event: any) {
    const inputElement = event.target;
    const inputValue = inputElement.value.replace(/\D/g, '');

    if (inputValue.length > 2) {
      const month = inputValue.substring(0, 2);
      const year = inputValue.substring(2, 4);

      inputElement.value = `${month}/${year}`;
    }
  }
}
